import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { SettingService } from './setting.service';
import { ApiModel } from '../data/api';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ConfigService } from '../utils/config.service';

@Injectable()
export class AuthV2Service {

  constructor(
    private http: HttpClient,
    private settingService: SettingService,
    private configService: ConfigService,
    private router: Router,
    private apiService: ApiService,
  ) {
  }

  getUserInfo(): Observable<ApiModel<any>> {
    const requestUrl = this.configService.getConfig('sokrates.url') + `/auth-http/v2/superapps/user-info`;

    return this.http.get<any>(requestUrl, this.settingService.httpOptionWithToken());
  }

}
