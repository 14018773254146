import { Component } from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeId from '@angular/common/locales/id';
import localeEn from '@angular/common/locales/en';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sokrates-superapps-cms-frontend';

  constructor() {
  }

  ngOnInit() {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeId, 'id');
  }
}
