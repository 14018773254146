import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/@core/api/auth.service';
import { AuthV2Service } from 'src/app/@core/api/auth-v2.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  isButtonDisabled = true;

  formLogin: FormGroup;

  eyeIconImages: string[] = [
    '../../../assets/img/Icons.svg',
    '../../../assets/img/pass-opened.svg'
  ];
  currentIconIndex: number = 0;
  loading = {
    submit: false,
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authV2Service: AuthV2Service,
  ) {
    this.formLogin = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });

  }

  getUserInfo() {
    this.authV2Service.getUserInfo()
      .subscribe((res: any) => {
        if (res.status) {
          localStorage.setItem('userInfo', JSON.stringify(res.data));
          this.router.navigate([`pages`], {relativeTo: this.route});
        }
      }), (err: any) => {
    }
  }

  onSubmit(){
    this.loading.submit = true;
    const data = {
      username: this.formLogin.get('username')?.value,
      email: this.formLogin.get('username')?.value,
      binusian_id: this.formLogin.get('username')?.value,
      password: this.formLogin.get('password')?.value,
      is_cms: true
    };

    this.authService.login(data).subscribe((res: any) => {
      if (res.message === 'Ok') {
        localStorage.setItem('appToken',res.data.token);
        this.getUserInfo();
        // this.loading.submit = false;
      } else {
        this.loading.submit = false;
        // this.pageLogin.message = res.message;

        // this.formLogin.markAsUntouched();
        // this.captchaRefresh();
      }
      // this.loading.login = false;
      // this.ref.detectChanges();
    }, (err: any) => {
      this.loading.submit = false;
      // this.loading.login = false;
      // this.ref.detectChanges();

      // this.formLogin.markAsUntouched();
      // this.captchaRefresh();
    });
  }
  togglePasswordVisibility(event: MouseEvent) {
    event.preventDefault();
    var passwordInput = <HTMLInputElement>document.getElementById("password");

    if (passwordInput !== null) {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        this.currentIconIndex = 1;
      } else {
        passwordInput.type = "password";
        this.currentIconIndex = 0;
      }
    }

  }

  get disableButton(): boolean {
    return this.formLogin.invalid;
  }



}
