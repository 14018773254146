import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable()
export class SettingService {

  constructor(
    private http: HttpClient,
  ) {
  }
  
  httpOptionWithToken() {
    return {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    };
  }

  httpOptionWithTokenJSON() {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }),
      body: {
      }
    };
  }

  getToken() {
    return localStorage.getItem('appToken');
  }

}
