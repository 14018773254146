// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  local: false,
  production: false,
  api: {
    url: 'api.apps.sokrates.xyz',
    secure: true,
    // url: 'localhost:3000',
    // secure: false,
  },
  upload: {
    url: 'apps.utils.sokrates.xyz:8001',
    secure: true,
    // url: 'localhost:3000',
    // secure: false,
  },
  sokrates: {
    url: 'api.sokrates.xyz',
    secure: true,
    // url: 'localhost:3000',
    // secure: false,
  },
  system: {
    url: 'system.api.sokrates.xyz:4430',
    secure: true,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
