import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { ConfigService } from '../utils/config.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../utils/local-storage.service';

@Injectable()
export class AuthService {
  private service = 'auth';

  constructor(
    private http: HttpClient,
    private router: Router,
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
  ) {
  }

  login(data: any): Observable<any> {
    const url = this.configService.getApiUrl(this.service, `/api/v1/login`);
    return this.http.post<any>(url, data);
  }

  logout(): any {
    this.localStorageService.clear();
    window.location.reload();
  }

}
